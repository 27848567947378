import axios from 'axios';
import reduxStore from '../store';

let API_URL;
if (location.hostname == 'manager.mlshipafrica.app') {
  API_URL = 'https://api.mlshipafrica.app/api'
}else if (location.hostname == 'rmexpress.storeino.world') {
    console.log("From Server", location.hostname);
    API_URL = "https://rmexpress.storeino.world/api"
} else if(location.hostname == 'rmexpress.speedliv.com') {
    API_URL = "https://api-rmexpress.speedliv.com/api";
}else{
    //API_URL = "https://api.mlshipafrica.app/api";
    API_URL = "http://" + location.hostname + ":3021/api";
    //API_URL = "http://167.71.1.17:3021/api";
}


const token = window.localStorage.getItem("id_token");

const HTTP = axios.create({
    baseURL: API_URL,
    headers: {
        'X-Auth-Token': token
    }
})

HTTP.interceptors.response.use(function (response) {
    // Do something with response data
   // console.log('respon  ',response)
    if(response.data && response.data.status == 401 && token){
        window.localStorage.removeItem('id_token');
        window.location.href = '/';
    }else if(response.data && response.data.status == 400 && token){
       alert(response.data.content)
    }else{
        const fe_version = response.headers['pragma'] || 'default'
       // console.log('pr-version-------------',fe_version)
        //console.log('pr-storage-------------',localStorage.getItem('pr-version'))
        if(token && response.config && response.config.method == 'get' && fe_version !== localStorage.getItem('pr-version')){
          //  console.log('-------------data changed-------------')
            //localStorage.setItem('pr-version', fe_version);
            reduxStore.dispatch("actionupdate",{update:true,pr_version:fe_version});
           // window.location.reload() // For new version, simply reload on any get
        }
        return response;
    }
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
  });

export default HTTP
